<template>
  <div class="flex items-center mt-2">
    <div
      v-if="filter.label"
      class="flex items-center w-56 select-none text-sm leading-tight"
    >
      {{ filter.label }}
    </div>
    <div class="w-full">
      <date-range-picker
        v-model="localValue"
        class="w-full"
        button-width="100%"
        :auto-close="autoClose"
        :date-format="dateFormat"
        :locale="locale"
        :no-future-dates="noFutureDates"
        :prevent-default-dates="preventDefaultDates"
        :date-time-mode="dateTimeMode"
        :min-date="minDate"
        :max-date="maxDate"
        css-classes="popper-middle"
        @clear="localValue = null"
      />
    </div>
  </div>
</template>

<script>
import DateRangePicker from '@/components/date-picker/range'

export default {
  components: {
    DateRangePicker
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    },
    locale: {
      type: String,
      required: true
    },
    dateFormat: {
      type: String,
      default: undefined
    },
    noFutureDates: {
      type: Boolean,
      default: true
    },
    preventDefaultDates: {
      type: Boolean,
      default: false
    },
    dateTimeMode: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue || {}
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  }
}
</script>
<style>
.popper-middle {
  margin-left: -456px !important;
}
</style>
